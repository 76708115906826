<template>
  <div
    v-if="show"
    ref="confimration_modal"
    :class="{ show: show === true }"
    :style="{ display: 'block' }"
    class="modal modal__confirm"
    id="confirmModal"
    tabindex=""
    role="dialog"
    aria-labelledby="modalLabel"
    aria-hidden="true"
  >
    <div class="w-100 h-100 d-flex">
      <div class="modal__dialog" role="document">
        <div class="modal__header mb-3">
          <div class="d-flex align-items-center justify-content-between">
            <span class="modal__header__title text--capital">
              {{ filterHeaderName }} Filter
            </span>
            <a
              class="modal__close modal__close--white d-flex align-items-center justify-content-between"
              @click="closeDeleteModal"
            >
              <span class="text--capital text--regular"> close </span>
              <ion-icon name="close-outline"></ion-icon>
            </a>
          </div>
          <span class="modal__header__subtitle text--capital"> </span>
        </div>
        <div class="modal__content">
          <div class="modal__content">
            <div class="modal__body">
              <div class="pt-5 filter-overview">
                <div
                  class="row align-items-end"
                  v-for="(itemTop, index) in filter"
                  :key="index"
                >
                  <div class="col-3 col-pad">
                    <div class="form__item">
                      <label
                        class="form__label text--black text--capital mb-3"
                        style="padding-left: 15px"
                        >{{ index === 0 ? "Where" : "And" }}</label
                      >

                      <select
                        required
                        class="select select--lg text--capital r-font"
                        v-model="itemTop.filterName"
                        @change="onChange(index)"
                      >
                        <option value="null" disabled selected v-if="!loading">
                          Select Type
                        </option>
                        <option value="null" disabled selected v-else>
                          Loading...
                        </option>
                        <template v-if="!loading">
                          <option
                            v-for="(item, index) in columns"
                            :key="index"
                            :value="item.fieldName"
                          >
                            {{ item.displayName }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>

                  <div
                    :class="[
                      itemTop.filterOp === 'bt'
                        ? 'col-2 col-pad '
                        : 'col-3 col-pad ',
                    ]"
                    v-if="itemTop.showOp"
                  >
                    <div class="form__item">
                      <select
                        required
                        class="select select--lg text--capital r-font"
                        v-model="itemTop.filterOp"
                        @change="onChangeFieldSet(index, itemTop.filterOp)"
                      >
                        <template>
                          <option
                            v-for="(item, index) in itemTop.filterOpArray"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.name }}
                          </option>
                        </template>
                      </select>
                    </div>
                  </div>

                  <div
                    v-if="emptyValueOperators.includes(itemTop.filterOp)"
                  ></div>

                  <div
                    style="display: flex; justify-content: space-between"
                    v-else-if="itemTop.filterOp === 'bt'"
                  >
                    <div style="width: 165px" class="form__item">
                      <input
                        type="text"
                        style="padding-left: 10px"
                        class="input form__input form__input--lg r-font"
                        placeholder="Enter value"
                        v-model="itemTop.value"
                      />
                    </div>

                    <div
                      style="width: 165px; padding-left: 10px"
                      class="form__item"
                    >
                      <input
                        type="text"
                        class="input form__input form__input--lg r-font"
                        placeholder="Enter value"
                        v-model="itemTop.value1"
                      />
                    </div>
                  </div>

                  <div
                    :class="[
                      itemTop.filterOp === 'bt'
                        ? 'col-3 col-pad '
                        : 'col-4 col-pad',
                    ]"
                    v-else-if="itemTop.showOp && itemTop.possibleValue.length"
                  >
                    <div class="form__item">
                      <multiselect
                        v-model="itemTop.value"
                        placeholder="Type to search"
                        open-direction="bottom"
                        :options="itemTop.possibleValue"
                        :multiple="false"
                        :searchable="true"
                        :show-labels="false"
                        :internal-search="true"
                        :clear-on-select="false"
                        :close-on-select="true"
                        :show-no-results="false"
                        :hide-selected="true"
                        :options-limit="500"
                        :limit="5"
                        :max-height="1000"
                      >
                      </multiselect>
                    </div>
                  </div>

                  <div
                    :class="[
                      itemTop.filterOp === 'bt'
                        ? 'col-3 col-pad'
                        : 'col-4 col-pad',
                    ]"
                    v-else
                  >
                    <div
                      class="form__item"
                      v-if="
                        itemTop.filterName.includes('Date') ||
                        itemTop.filterName.includes('date') ||
                        getPenaltyDueValue(itemTop.filterName)
                      "
                    >
                      <input
                        type="date"
                        :pattern="'\d{4}/\d{2}/\d{2}'"
                        class="input form__input form__input--lg r-font"
                        placeholder="Enter value"
                        v-model="itemTop.dateValue"
                        @change="getFormattedDate(index, $event)"
                      />
                    </div>

                    <div
                      class="form__item"
                      v-else-if="
                        itemTop.filterName.toLowerCase() ===
                          'account_balance' ||
                        getPenaltyDueValue(itemTop.filterName)
                      "
                    >
                      <input
                        type="number"
                        :min="0"
                        class="input form__input form__input--lg"
                        placeholder="Enter value"
                        v-model="itemTop.value"
                      />
                    </div>
                    <div class="form__item" v-else>
                      <input
                        type="text"
                        class="input form__input form__input--lg r-font"
                        placeholder="Enter value"
                        v-model="itemTop.value"
                      />
                    </div>
                  </div>

                  <!-- <div :class="[itemTop.filterOp === 'bt' ? 'col-3 col-pad' : 'col-4 col-pad d-none']" >
                    <div
                      class="form__item"
                      v-if="
                        itemTop.filterName.includes('Date') || 
                        itemTop.filterName.includes('date')
                      "
                    >
                      <input
                        type="date"
                        class="input form__input form__input--lg r-font"
                        placeholder="Enter value"
                        v-model="itemTop.value1"
                      />
                    </div>
                  </div> -->

                  <div class="col-1">
                    <div class="form__item">
                      <button
                        @click="deleteFilter(index)"
                        class="button m-1 page__body__header__button modal__confirm__button--delete button--sm text--black w-100 text--capital"
                      >
                        <span>x</span>
                      </button>
                    </div>
                  </div>
                </div>

                <div class="row ml-3">
                  <button
                    type="button"
                    class="button page__body__header__button button--grey text--capital ml-3"
                    @click="addFilter()"
                  >
                    <span class="d-inline-flex mr-2">add new filter</span>
                    <ion-icon
                      name="add-outline"
                      class="text--medium"
                    ></ion-icon>
                  </button>
                </div>
              </div>

              <div class="d-flex mt-5">
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--cancel mr-2"
                  @click="closeDeleteModal"
                >
                  cancel
                </button>
                <!-- <button
                  data-dismiss="#confirmModal"
                  class="
                    button button--lg
                    modal__confirm__button modal__confirm__button--delete
                    ml-2
                    save-changes
                  "
                  @click="deactivateStatus()"
                  v-if="loading"
                >
                  <div class="spinner-border texxt-light" role="status"></div>
                </button> -->
                <button
                  data-dismiss="#confirmModal"
                  class="button button--lg modal__confirm__button modal__confirm__button--delete ml-2 save-changes"
                  @click="activateFilter()"
                >
                  confirm
                </button>

                <button
                  data-dismiss="#confirmModal"
                  style="border-color: black"
                  class="button button--lg modal__confirm__button modal__confirm__button--info ml-3"
                  @click="clearFilter"
                >
                  clear
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
import GeneralApiService from "@/core/services/general.service";
// import LoanApiService from "@/core/services/api.loan.service";
import Multiselect from "vue-multiselect";
import moment from "moment";
export default {
  name: "FilterComponent",
  props: {
    show: Boolean,
    close: Function,
    filterHeaderName: String,
    filterCategory: String,
    filterEditValue: Array,
    showEditedValue: Number,
  },
  computed: {
    bt() {
      return "bt";
    },
  },
  watch: {
    showEditedValue: function (val) {
      if (val) {
        this.editFilter();
      }
    },
  },
  data() {
    return {
      loading: false,
      format: "yyyy-MM-dd",
      filter: [
        {
          filterName: "",
          showOp: false,
          filterOpArray: [],
          filterOp: "eq",
          value: "",
          value1: "",
          dateValue: "",
          filterValue: [],
          possibleValue: [],
        },
      ],
      columns: null,
      possibleValues: [],
      emptyValueOperators: [
        "em",
        "nem",
        "null",
        "nnull",
        "yd",
        "td",
        "lasy",
        "lasw",
        "lasm",
        "thm",
        "thw",
        "empty",
        "ne",
        "emp",
        "yt",
        "td",
        "lm",
        "ly",
        "tm",
        "thy",
      ],
      excludedType: ["account_officer_reassign"],
      rolePermissions: [
        "is_support",
        "is_administrator",
        "is_credit_officer",
        "is_teller",
        "is_delete",
      ],
    };
  },
  components: {
    Multiselect,
  },
  methods: {
    getPenaltyDueValue(name) {
      if (name.includes("Date") && this.filterCategory === "installment")
        return true;
      if (name.includes("Penalty") && this.filterCategory === "installment")
        return false;
      return false;
    },
    getFormattedDate(index, e) {
      const value = e.target.value;
      this.filter[index].value = moment(value).format("YYYY/MM/DD");
    },
    addFilter() {
      this.filter.push({
        filterName: "",
        showOp: false,
        filterOpArray: [],
        filterOp: "",
        filterValue: [],
        value: "",
        dateValue: "",
        value1: "",
        possibleValue: [],
      });
    },
    editFilter() {
      let data = [];
      this.filterEditValue.forEach(async (item) => {
        // Assign variables
        const fieldName = item.filterFieldName
          ? item.filterFieldName
          : item.filterField;
        const filterOperator = item.filterFieldOperator
          ? item.filterFieldOperator
          : item.operation;
        const values = item.filterFieldValues
          ? item.filterFieldValues
          : item.fieldValues;
        const possibleOp = this.columns.filter(
          (items) => items.fieldName.toLowerCase() === fieldName.toLowerCase()
        );

        // Initialize new object
        const obj = {};
        obj.filterName = possibleOp[0].fieldName;
        obj.filterOp = filterOperator;
        obj.filterOpArray = possibleOp[0].possibleFilterFieldOperators
          ? possibleOp[0].possibleFilterFieldOperators
          : possibleOp[0].possibleOperators;
        obj.filterValue = [];
        obj.showOp = true;
        obj.value = values[0];
        obj.dateValue = moment(values[0]).format("YYYY-MM-DD");
        obj.value1 = values[1] ? values[1] : "";
        obj.possibleValue = await this.getValueFields(fieldName);
        if (this.filterCategory.toLowerCase() === "role") {
          if (this.rolePermissions.includes(fieldName)) {
            obj.possibleValue = ["True", "False"];
            obj.value = values[0] == "1" ? "True" : "False";
          }
        }
        data.push(obj);
      });
      this.filter = data;
    },
    async onChange(index) {
      this.filter[index].possibleValue = [];
      this.filter[index].filterOp = "empty";
      if (this.excludedType.includes(this.filterCategory)) {
        this.filter[index].filterValue = "";
        this.filter[index].showOp = true;
        const possibleOp = this.columns.filter(
          (items) => items.fieldName === this.filter[index].filterName
        );
        this.filter[index].filterValue = [];
        this.filter[index].filterOpArray = possibleOp[0].possibleOperators;
        const name = this.filter[index].filterName;
        this.filter[index].filterOp = "eq";
        this.filter[index].possibleValue = await this.getValueFields(name);
        if (name.toLowerCase() === "creation_date") {
          this.filter[index].filterOp = "bt";
          return;
        }
      } else {
        this.filter[index].filterValue = "";
        this.filter[index].showOp = true;
        const possibleOp = this.columns.filter(
          (items) => items.fieldName === this.filter[index].filterName
        );
        this.filter[index].filterValue = [];
        this.filter[index].filterOpArray = possibleOp[0].possibleOperators
          ? possibleOp[0].possibleOperators
          : possibleOp[0].possibleFilterFieldOperators;
        const name = this.filter[index].filterName;
        this.filter[index].filterOp = "eq";
        this.filter[index].possibleValue = await this.getValueFields(name);
        if (name.toLowerCase() === "creation_date") {
          this.filter[index].filterOp = "bt";
          return;
        }
      }

      if (this.filterCategory.toLowerCase() === "role") {
        if (this.rolePermissions.includes(this.filter[index].filterName)) {
          this.filter[index].possibleValue = ["True", "False"];
        }
      }
    },
    async onChangeFieldSet(index, filterOp) {
      const freeTextOp = [
        "contains",
        "notContains",
        "s",
        "e",
        "S",
        "E",
        "EQ",
        "NEQ",
      ];
      const option = filterOp;
      this.filter[index].filterOp = "empty";
      this.filter[index].value = "";
      this.filter[index].value1 = "";

      if (freeTextOp.includes(filterOp)) {
        this.filter[index].possibleValue = [];
        this.filter[index].filterOp = option;
      } else {
        const name = this.filter[index].filterName;
        if (this.filterCategory.toLowerCase() === "role") {
          if (this.rolePermissions.includes(this.filter[index].filterName)) {
            this.filter[index].possibleValue = ["True", "False"];
            this.filter[index].filterOp = option;
            return;
          }
        }
        this.filter[index].possibleValue = await this.getValueFields(name);
        this.filter[index].filterOp = option;
      }
    },
    deleteFilter(e) {
      if (this.filter.length == 1) {
        this.$emit("alertError", "At least one filter must be present");
        return;
      }
      this.filter.splice(e, 1);
    },
    getColumnNames() {
      this.loading = true;
      // if(this.filterCategory === "installment") {
      //   LoanApiService.get(
      //     "LoanAccount/get-schedules-fields"
      //   )
      //     .then((resp) => {
      //       this.loading = false;
      //       this.columns = resp.data.data.data;
      //     })
      //     .catch((error) => {
      //       this.loading = false;
      //       this.$emit("alertError", error.response.data.message);
      //     });
      // } else
      if (this.filterCategory === "sales_report") {
        GeneralApiService.get("SalesReport/get-sales-report-columns")
          .then((resp) => {
            this.loading = false;
            this.columns = resp.data.data.data;
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("alertError", error.response.data.message);
          });
      } else {
        GeneralApiService.post("GeneralFilter/GetEntityFilterColumns", {
          filterCategory: this.filterCategory,
        })
          .then((resp) => {
            this.loading = false;
            this.columns = resp.data.data;
          })
          .catch((error) => {
            this.loading = false;
            this.$emit("alertError", error.response.data.message);
          });
      }
    },
    async getValueFields(name) {
      if (this.filterCategory === "sales_report") {
        const resp = await GeneralApiService.post(
          `SalesReport/get-sales-report-columns-values`,
          { fieldName: name }
        );
        this.possibleValues = resp.data ? resp.data : [];
      }
      // else if (this.filterCategory === "installment") {
      //   const resp = await ApiService.get(
      //     `http://deposit.proleanfinancials.biz/GetPossibleFilterValuesForFieldName?FiledName=${name}&fieldType=${this.filterCategory}`
      //   );
      //   this.possibleValues = resp.data.data;
      // }
      else if (this.filterCategory === "activity") {
        const resp = await GeneralApiService.post(
          `GeneralFilter/PossibleFilterValue`,
          { fieldName: name, filterCategory: this.filterCategory }
        );
        this.possibleValues = resp.data.data;
      } else {
        const type = this.filterCategory === "loan" ? "loan" : "";
        const resp = await ApiService.get(
          `GetPossibleFilterValuesForFieldName?FiledName=${name}&fieldType=${type}`
        );
        this.possibleValues = resp.data.data;
      }

      return this.possibleValues;
    },
    resetForm() {
      this.filter = [
        {
          filterName: "",
          showOp: false,
          filterOpArray: [],
          filterOp: "",
          value: "",
          value1: "",
          filterValue: [],
          possibleValue: [],
        },
      ];
    },
    closeDeleteModal() {
      this.resetForm();
      this.$emit("close");
    },
    clearFilter() {
      this.resetForm();
      this.$emit("reload");
      this.$emit("close");
    },
    activateFilter() {
      for (let index in this.filter) {
        if (
          this.filter[index].filterName === "" &&
          (this.filter[index].filterOp === "" ||
            this.filter[index].filterOp === "eq")
        ) {
          this.$emit(
            "alertError",
            "All filter items should contains at least filter display name, filter option and filter value."
          );
          return;
        }
      }
      this.$emit("close");
      this.$emit("send-activate-filter", this.filter);
      this.resetForm();
    },
  },
  mounted() {
    this.getColumnNames();
  },
};
</script>
<style lang="scss" scoped>
.modal__content {
  padding: 2.5rem;
}
.r-font {
  font-size: 11px !important;
}
.filter-overview {
  max-height: 500px;
  overflow: scroll;
  overflow-x: hidden;
}
.m-1 {
  margin-left: -1rem !important;
}
.col-pad {
  padding-right: 6px;
  padding-left: 6px;
}
@media (min-width: 560px) {
  .modal__confirm .modal__dialog {
    max-width: 850px;
    margin: auto;
  }
}
</style>
